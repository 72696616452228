export const FIXED_LINE = 'FIXED_LINE'
export const VARIABLE_LINE = 'VARIABLE_LINE'
export const SECURED_LINE = 'SECURED_LINE'

export const getSideBarImage = (isCounterOffer) => {
  if (isCounterOffer) {
    const counterOfferPath = 'https://app.divvy.co/assets/illustrations/gift-02'
    const counterOfferSrcSet = `${counterOfferPath}@3x.png, ${counterOfferPath}@2x.png, ${counterOfferPath}@1x.png`
    return counterOfferSrcSet
  }

  const businessTransactionPath = 'https://app.divvy.co/assets/illustrations/business-transaction-01'
  const businessTransactionSrcSet = `${businessTransactionPath}@3x.png, ${businessTransactionPath}@2x.png, ${businessTransactionPath}@1x.png`
  return businessTransactionSrcSet
}

// Testing utils

const salesforceCreditId = 'a8sWB000000EMv7YAG'
export const noTilaMock = {
  data: {
    creditApplication: {
      salesforceCreditId: salesforceCreditId,
      authorizedSigner: {
        email: 'test@example.com',
      },
      dsaVersion: 'crb_j_s',
      recordType: 'new',
      financeInfo: {
        bankInfo: {
          accountNumberLastFour: '5432',
        },
      },
    },
  },
}

export const tilaNotSignedMock = {
  data: {
    creditApplication: {
      salesforceCreditId: salesforceCreditId,
      authorizedSigner: {
        email: 'test@example.com',
      },
      dsaVersion: 'crb_j_s',
      recordType: 'new',
      financeInfo: {
        bankInfo: {
          accountNumberLastFour: '5432',
        },
      },
      tila: {
        content: 'tila content',
      },
    },
  },
}

export const signedTilaData = {
  data: {
    creditApplication: {
      salesforceCreditId: salesforceCreditId,
      authorizedSigner: {
        email: 'test@example.com',
      },
      dsaVersion: 'crb_j_s',
      recordType: 'new',
      financeInfo: {
        bankInfo: {
          accountNumberLastFour: '5432',
        },
      },
      tila: {
        content: 'tila content',
        meta: {
          signedAt: '2024-10-16T23:54:27.682',
        },
      },
    },
  },
}

export const testDecisionId = 'decisionId123'
const approvedAmountInCents = 1000000

export const securedDecisionContextValue = {
  securedLineOffer: {
    id: testDecisionId,
    offerType: SECURED_LINE,
    underwritingMethod: 'secured_line',
  },
  isLoggedInUserAuthorizedSigner: true,
  authSignerEmail: 'test@example.com',
  desiredCredit: 1000000,
}

export const variableDecisionContextValue = {
  variableLineOffer: {
    id: testDecisionId,
    offerType: VARIABLE_LINE,
    approvedAmountInCents: approvedAmountInCents,
    underwritingMethod: 'auto_cash_underwriting',
  },
  isLoggedInUserAuthorizedSigner: true,
  authSignerEmail: 'test@example.com',
  desiredCredit: 1000000,
}

export const standardDecisionContextValue = {
  standardLineOffer: {
    id: testDecisionId,
    offerType: FIXED_LINE,
    approvedAmountInCents: approvedAmountInCents,
    underwritingMethod: 'auto_europa_h20',
  },
  isLoggedInUserAuthorizedSigner: true,
  authSignerEmail: 'test@example.com',
  desiredCredit: 1000000,
}
