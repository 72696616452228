import React from 'react'
import { useLocation } from 'react-router-dom'
import SmsAccessCodeForm from '../SmsAccessCodeForm'
import PhoneNumberMfaModalContainer from '../PhoneNumberMfaContainer/PhoneNumberMfaModalContainer'

const InApplicationMfaModalContainer = () => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const verifyAccessCode = !!queryParams.get('verifyAccessCode')

  if (verifyAccessCode) return <SmsAccessCodeForm isModal />
  return <PhoneNumberMfaModalContainer />
}

export default InApplicationMfaModalContainer
