import React, { useState } from 'react'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import { css } from '@emotion/core'
import Form from '@divvy-web/skylab.form'
import { useLocation, useNavigate } from 'react-router-dom'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import {
  PAGE_NAME_STRINGS,
  PATHNAME_CONSTS,
  PRODUCT_CONST_STRINGS,
  RightFitSurveyPageContent,
} from '../../resources/constants'
import ProgramFitForm from './ProgramFitForm'

const RightFitSurveyPage = () => {
  const makeTestId = useMakeTestId('RightFitSurveyPage')
  const navigate = useNavigate()
  const { search, state } = useLocation()
  const searchParams = search ?? ''
  const checkBoxSections = RightFitSurveyPageContent
  const { PAGE_RIGHT_FIT_SURVEY } = PAGE_NAME_STRINGS

  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const formInitialValues = checkBoxSections.reduce((acc, current) => {
    const { checkBoxElements } = current
    checkBoxElements.forEach((value) => {
      acc = { [value.name]: false, ...acc }
    })
    return acc
  }, {})

  const handlePreviousClick = () => {
    navigate({ pathname: PATHNAME_CONSTS.VERIFY_EMAIL_PATH, search: searchParams }, { state })
  }

  const handleChange = (values) => {
    const selections = Object.values(values).filter((selection) => selection !== false)
    if (selections.length >= checkBoxSections.length) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }

  const handleSubmit = (_, values) => {
    // program fit determination by first option selection for checkbox groups 2 and 3 and 1 or 2 for group 4
    if (values.group1_element0 && values.group2_element0 && (values.group3_element0 || values.group3_element1)) {
      navigate({ pathname: PATHNAME_CONSTS.NOT_GOOD_FIT_PATH, search: searchParams })
    } else {
      navigate({ pathname: PATHNAME_CONSTS.GOOD_FIT_PATH, search: searchParams })
    }
  }

  return (
    <>
      <PageViewWrapper pageName={PAGE_RIGHT_FIT_SURVEY}>
        <MiscPageWrapper
          headingStyles={null}
          pageName={PAGE_RIGHT_FIT_SURVEY}
          pageStyles={null}
          pageSubTitle={
            <FormattedMessage
              defaultMessage='Let’s review a few things up front to make sure we’re a match.'
              id='sputnik.RightFitSurveyPage__dw1JHc'
            />
          }
          pageTitle={
            <FormattedMessage
              defaultMessage='Is {BILL_SPEND_AND_EXPENSE} the right fit for you?'
              id='sputnik.RightFitSurveyPage__hhobD+'
              values={{
                BILL_SPEND_AND_EXPENSE: PRODUCT_CONST_STRINGS.BILL_SPEND_AND_EXPENSE,
              }}
            />
          }
        >
          <Form
            initialValues={formInitialValues}
            onChange={handleChange}
            onSubmit={handleSubmit}
          >
            <div
              className='fs-unmask'
              css={programFitFormCss}
            >
              <ProgramFitForm formSections={checkBoxSections} />
              <div className='formFooterNote'>
                <FormattedMessage
                  defaultMessage='Note: the authorized signer is the person who is responsible for signing the credit application.'
                  id='sputnik.RightFitSurveyPage__iPWaQT'
                />
              </div>
              <div className='navigation-buttons'>
                <BasicButton
                  className='secondary-button'
                  color='neutral'
                  type={BASIC_BUTTON_TYPE_FLAT}
                  onClick={handlePreviousClick}
                >
                  <FormattedMessage
                    defaultMessage='Previous'
                    id='sputnik.RightFitSurveyPage__JJNc3c'
                  />
                </BasicButton>
                <BasicButton
                  buttonType='submit'
                  className='primary-button'
                  color='action'
                  dataTestId={makeTestId('primary')}
                  disabled={isButtonDisabled}
                >
                  <FormattedMessage
                    defaultMessage='Next'
                    id='sputnik.RightFitSurveyPage__9+Ddtu'
                  />
                </BasicButton>
              </div>
            </div>
          </Form>
        </MiscPageWrapper>
      </PageViewWrapper>
    </>
  )
}

const programFitFormCss = ({ mq, type }) => css`
  margin-bottom: var(--tri-space-1500);
  ${mq.xSmallMinWidth({ marginBottom: 'var(--tri-space-200)' })};
  .programFitFormSection {
    display: flex;
    flex-direction: column;
    gap: var(--tri-space-400);
    margin-bottom: var(--tri-space-500);
  }

  .checkBoxSection {
    display: flex;
    flex-direction: column;
    ${mq.xSmallMinWidth({ flexDirection: 'row' })};
    gap: var(--tri-space-300);
  }

  .sectionTitle {
    ${type.triFontDesktopBodySmallEmphasis}
  }

  .formFooterNote {
    ${type.triFontDesktopBodySmall}
  }

  .navigation-buttons {
    position: fixed;
    right: 25%;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    margin: var(--tri-space-500) 0 var(--tri-space-500) 0;
    padding-right: 15%;
    gap: var(--tri-space-400);

    ${mq.largeMaxWidth({ right: 0 })}

    ${mq.xxSmallMaxWidth({
      gap: '0',
      height: 'var(--tri-space-1100)',
      justifyContent: 'space-between',
      margin: 0,
      padding: 0,
      right: 0,
      width: '100%',
    })}
  }

  .primary-button {
    order: 2;
    ${mq.xxSmallMaxWidth({ flexGrow: '1', minWidth: '50%' })};
  }

  .secondary-button {
    order: 1;
    ${mq.xxSmallMaxWidth({
      backgroundColor: 'var(--tri-color-fill-primary)',
      flexGrow: '1',
      minWidth: '50%',
    })};
  }

  .navigation-buttons button {
    ${mq.xxSmallMaxWidth({ borderRadius: '0', height: '100%', width: '100%' })};
  }
`

export default RightFitSurveyPage
