import React, { ReactNode } from 'react'
import Form from '@divvy-web/skylab.form'
import { css } from '@emotion/core'

const accessCodeValidations = {
  accessCode: {
    presence: {
      message: 'Please enter a valid access code',
    },
  },
}

interface AccessCodeFormProps {
  disableError: () => void
  children?: ReactNode
  handleSubmitAccessCode: (accessCode: string) => void
}

const AccessCodeForm = ({ disableError, handleSubmitAccessCode, children }: AccessCodeFormProps) => {
  return (
    <>
      <Form
        css={accessCodeFormCss}
        id='mfaPhoneNumber'
        validation={accessCodeValidations}
        onChange={() => disableError()}
        onSubmit={(_, { accessCode }) => handleSubmitAccessCode(accessCode)}
      >
        {children}
      </Form>
    </>
  )
}

const accessCodeFormCss = css`
  .field-container {
    max-width: none;
  }
`

export default AccessCodeForm
