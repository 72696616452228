import React, { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import TextLink from '@divvy-web/skylab.textlink'
import { css } from '@emotion/core'
import { FormTextInput } from '@divvy-web/skylab.form'
import FormElementWrapper from '../FormInputs/FormElementWrapper'
import AutoCompleteDisable from '../FormInputs/AutoCompleteDisable'

interface AccessCodeFieldProps {
  handleResendClick: () => void
  hasError: boolean
  processDescription: ReactNode
}

const AccessCodeField = ({ handleResendClick, hasError, processDescription }: AccessCodeFieldProps) => {
  return (
    <>
      <div
        className='info-text fs-unmask'
        css={accessCodeInfoTextCss}
        data-testid='email-text'
      >
        {processDescription}
      </div>
      <div className='field-container fs-unmask'>
        <FormElementWrapper
          errorCaption={
            <FormattedMessage
              defaultMessage='This access code is incorrect or expired'
              id='sputnik.AccessCodeField__kBKP+N'
            />
          }
          hasError={hasError}
        >
          <FormTextInput
            // @ts-expect-error
            autoComplete='none'
            className='access-code fs-mask'
            dataTestId='access-code-field'
            inputMode='numeric'
            label={
              <FormattedMessage
                defaultMessage='Verification code'
                id='sputnik.AccessCodeField__/6gLBc'
              />
            }
            name='accessCode'
            placeholder={
              <FormattedMessage
                defaultMessage='Verification code'
                id='sputnik.AccessCodeField__/6gLBc'
              />
            }
          />
        </FormElementWrapper>
      </div>
      <AutoCompleteDisable />
      <TextLink
        className='resend-code-link fs-unmask'
        css={resendCodeLinkCss}
        dataTestId='access-code-resend-link'
        onClick={handleResendClick}
      >
        <FormattedMessage
          defaultMessage='Resend code'
          id='sputnik.AccessCodeField__ZD48TW'
        />
      </TextLink>
    </>
  )
}

const accessCodeInfoTextCss = ({ mq }) => css`
  display: flex;
  margin-bottom: var(--tri-space-450);
  ${mq.xSmallMaxWidth({ marginBottom: 'var(--tri-space-550)', width: '100%' })};
`

const resendCodeLinkCss = css`
  margin-top: var(--tri-space-300);
`

export default AccessCodeField
