import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CreditApplicationStatus } from '_gql'
import { useGetApplicationStatusForPolling } from '../../pages/gql/GetApplicationStatusForPolling.gql'
import useApplicantTokenOnMount from '../../hooks/useApplicantTokenOnMount'
import AcceptingOfferPollingPageContent from './AcceptingOfferPollingPageContent'

const POLLING_MAX_TIMEOUT = 60000
const POLLING_RATE = 5000

const AcceptingOfferPollingPage = () => {
  /* make sure we have a token before we start polling */
  const { appId } = useParams()
  useApplicantTokenOnMount({ salesforceCreditId: appId || '' })

  const [timer, setTimer] = useState(0)

  const { data, loading, startPolling, stopPolling } = useGetApplicationStatusForPolling({
    fetchPolicy: 'no-cache',
    skip: !appId,
    variables: { creditApplicationId: appId as string },
  })

  const { status } = data?.creditApplication || {}
  const hasPollingTimedOut = timer >= POLLING_MAX_TIMEOUT
  const { APPROVED, OFFER_ACCEPTED, MORE_INFO_NEEDED, CORRECTION_REQUESTED, IN_REVIEW } = CreditApplicationStatus
  const shouldNavigateImmediatelyStatus =
    status !== null &&
    [APPROVED, OFFER_ACCEPTED, MORE_INFO_NEEDED, CORRECTION_REQUESTED, IN_REVIEW].includes(
      status as CreditApplicationStatus,
    ) &&
    timer > POLLING_RATE

  const finishedPolling = (hasPollingTimedOut || shouldNavigateImmediatelyStatus) && !loading && data
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false)
  // handle polling
  useEffect(() => {
    startPolling(POLLING_RATE)
    if (hasPollingTimedOut) {
      setIsReadyToNavigate(true)
    } else {
      setTimeout(() => setTimer(timer + POLLING_RATE), POLLING_RATE)
    }
  }, [hasPollingTimedOut, startPolling, stopPolling, timer])

  return (
    <AcceptingOfferPollingPageContent
      key={(isReadyToNavigate || false).toString()}
      isReadyToNavigate={(finishedPolling as boolean) || (isReadyToNavigate as boolean)}
      stopPolling={() => stopPolling()}
    />
  )
}

export default AcceptingOfferPollingPage
