import React from 'react'
import { func, bool, array, string, object } from 'prop-types'
import { css } from '@emotion/core'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormTextInput } from '@divvy-web/skylab.form'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormElementWrapper } from '../../../../components'
import InviteCollaboratorsChipList from '../InviteCollaboratorsChipList/InviteCollaboratorsChipList'

const InviteCollaboratorsForm = ({
  creditApplicationPeopleEmails,
  isMobile,
  allCollaboratorEmails,
  onEmailRemovalClick,
  control,
}) => {
  const [makeTestId, getClassName] = useNameFormatter('InviteCollaboratorsForm')

  return (
    <div
      className='fs-unmask'
      css={isMobile ? webStyles : mobileStyles}
      data-testid={makeTestId('container')}
    >
      <header className={getClassName('header')}>
        <div className={getClassName('title')}>
          <FormattedMessage
            defaultMessage='Invite collaborators'
            id='sputnik.inviteCollaboratorsUtils__ni18Y2'
          />
        </div>
        <div className={getClassName('subtitle')}>
          <FormattedMessage
            defaultMessage='Please enter the email of the person you would like to invite to help you fill out this application.'
            id='sputnik.InviteCollaboratorsForm__HCKlRM'
          />
        </div>
      </header>
      <div>
        <form onSubmit={(e) => e.preventDefault()}>
          <FormElementWrapper>
            <FormTextInput
              alwaysShowError
              caption={
                <FormattedMessage
                  defaultMessage='Separate multiple emails with commas'
                  id='sputnik.inviteCollaboratorsUtils__HCKlRM'
                />
              }
              className={getClassName('email-input')}
              control={control}
              dataTestId='InviteCollaborators-Field'
              label={
                <FormattedMessage
                  defaultMessage='Email'
                  id='sputnik.InviteCollaboratorsForm__sy+pv5'
                />
              }
              name='collaboratorsEmailInput'
              placeholder={
                <FormattedMessage
                  defaultMessage='Enter email'
                  id='sputnik.InviteCollaboratorsForm__KinTIy'
                />
              }
            />
          </FormElementWrapper>
        </form>
        <InviteCollaboratorsChipList
          allCollaboratorEmails={allCollaboratorEmails}
          creditApplicationPeopleEmails={creditApplicationPeopleEmails}
          onEmailRemovalClick={onEmailRemovalClick}
        />
      </div>
    </div>
  )
}

const webStyles = ({ type }) => css`
  .InviteCollaboratorsForm-title {
    ${type.triFontDesktopTitleSmallEmphasis}
  }

  .InviteCollaboratorsForm-subtitle {
    ${type.triFontDesktopBodyMediumStandard}
  }
`

const mobileStyles = ({ type }) => css`
  .InviteCollaboratorsForm-title {
    ${type.triFontDesktopTitleSmallEmphasis}
  }

  .InviteCollaboratorsForm-subtitle {
    ${type.triFontDesktopBodyMediumStandard}
    margin-bottom: var(--tri-space-300);
  }
`

InviteCollaboratorsForm.propTypes = {
  creditApplicationPeopleEmails: array,
  isMobile: bool,
  allCollaboratorEmails: array,
  onEmailRemovalClick: func,
  control: object,
}

export default InviteCollaboratorsForm
