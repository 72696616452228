import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { CreditApplication, CreditApplicationStatus, Decision } from '_gql'
import useDivvyUUID from '../../hooks/useDivvyUUID'
import useApplicantTokenOnMount from '../../hooks/useApplicantTokenOnMount'
import { useGetDecisionAndStatusForPolling } from '../gql/GetDecisionAndStatusForPolling.gql'
import SubmittingApplicationPollingPageContent from './SubmittingApplicationPollingPageContent'

const POLLING_MAX_TIMEOUT = 60000
const POLLING_RATE = 3000

export interface GetDecisionAndStatusForPollingType {
  creditApplication: CreditApplication
  decisions: Decision[]
  underwritingDeclined: boolean
  frozenAuthorizedSigner: boolean
}

const SubmittingApplicationPollingPage = () => {
  /* make sure we have a token before we start polling */
  const { appId } = useParams()
  useApplicantTokenOnMount({ salesforceCreditId: appId || '' })
  const { pathname, search: urlSearch } = useLocation()
  const [timer, setTimer] = useState(0)
  const [companyDivvyUuid] = useDivvyUUID()

  const { data, loading, startPolling, stopPolling } = useGetDecisionAndStatusForPolling({
    variables: {
      creditApplicationId: appId as string,
      companyId: companyDivvyUuid,
    },
    pollInterval: POLLING_RATE,
    fetchPolicy: 'no-cache',
  })
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false)

  const { creditApplication, frozenAuthorizedSigner } = (data as unknown as GetDecisionAndStatusForPollingType) || {}
  const { status } = (creditApplication as CreditApplication) || {}
  const { REJECTED, NOT_STARTED, IN_PROGRESS, AWAITING_SIGNATURE, REVIEW_OFFER, MORE_INFO_NEEDED, APPROVED } =
    CreditApplicationStatus
  const hasPollingTimedOut = timer >= POLLING_MAX_TIMEOUT
  const isPreSubmitStatus = status !== null && [NOT_STARTED, IN_PROGRESS, AWAITING_SIGNATURE].includes(status)
  const shouldNavigateImmediatelyStatus =
    status !== null && [REJECTED, MORE_INFO_NEEDED, REVIEW_OFFER, APPROVED].includes(status)
  const finishedPolling =
    (hasPollingTimedOut || shouldNavigateImmediatelyStatus || frozenAuthorizedSigner) && !loading && data

  // save url to redirect back to it in case user leaves this page
  useEffect(() => {
    window.sessionStorage.setItem('submissionPendingUrl', pathname + urlSearch)
  }, [appId, pathname, urlSearch])

  // handle polling
  useEffect(() => {
    startPolling(POLLING_RATE)
    if (hasPollingTimedOut) {
      stopPolling()
      setIsReadyToNavigate(true)
    } else {
      setTimeout(() => setTimer(timer + POLLING_RATE), POLLING_RATE)
    }
  }, [hasPollingTimedOut, startPolling, stopPolling, timer])

  return (
    <>
      {data && (
        <SubmittingApplicationPollingPageContent
          key={(isReadyToNavigate || false).toString()}
          decisionAndStatusData={data as unknown as GetDecisionAndStatusForPollingType}
          isPreSubmitStatus={isPreSubmitStatus}
          isReadyToNavigate={(finishedPolling as boolean) || (isReadyToNavigate as boolean)}
          stopPolling={() => stopPolling()}
        />
      )}
    </>
  )
}

export default SubmittingApplicationPollingPage
