import React, { ReactNode } from 'react'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import Tile from '@divvy-web/skylab.tile'
import Icon from '@divvy-web/skylab.icon'
import { statusTileContentWebCss } from '../statusTileStyles'

interface StatusTileWebViewProps {
  businessName: string
  primaryInfo: ReactNode
  secondaryInfo: ReactNode
  image: ReactNode
  children?: ReactNode
  title: ReactNode
  isWarningStatus: boolean
}

const StatusTileWebView = ({
  businessName,
  primaryInfo,
  secondaryInfo,
  image,
  children,
  isWarningStatus,
  title,
}: StatusTileWebViewProps) => {
  const [getClassName, makeTestId] = useNameFormatter('StatusTileWebView')

  return (
    <div
      css={statusTileContentWebCss}
      data-testid={makeTestId('')}
    >
      <div className='fs-unmask'>
        {businessName && (
          <div
            className={getClassName('business-name')}
            data-testid={makeTestId('business-name')}
          >
            {businessName}
          </div>
        )}
        <Tile>
          <div className={getClassName('content')}>
            <div className={getClassName('image')}>{image}</div>
            <div className={getClassName('info-container')}>
              <div className={getClassName('main-title')}>
                {title}
                {isWarningStatus && (
                  <Icon
                    className={getClassName('')}
                    name='warningColor'
                    size='large'
                  />
                )}
              </div>
              <div
                className={isWarningStatus ? 'status-error-text' : 'status-sub-header'}
                data-testid={makeTestId('primary-info')}
              >
                {primaryInfo}
              </div>
              <div className={getClassName('description')}>{secondaryInfo}</div>
              {children && <div className={getClassName('button-container')}>{children}</div>}
            </div>
          </div>
        </Tile>
      </div>
    </div>
  )
}

export default StatusTileWebView
