import React from 'react'
import BasicButton from '@divvy-web/skylab.basicbutton'
import { FormattedMessage } from '@divvy-web/i18n'
import { FormConsumer } from '@divvy-web/skylab.form'
import MfaModal from '../MfaModal/MfaModal'
import MfaPhoneNumberField from '../MfaPhoneNumberField/MfaPhoneNumberField'
import MfaPhoneNumberForm from '../MfaPhoneNumberForm/MfaPhoneNumberForm'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import MfaPopsheet from '../MfaPopsheet/MfaPopsheet'

const PhoneNumberMfaModalContainer = () => {
  const { isDesktop } = useDeviceDetect()

  const title = (
    <>
      <FormattedMessage
        defaultMessage='Verify updated mobile number'
        id='sputnik.PhoneNumberMfaContainer__C5F7en'
      />
    </>
  )

  return (
    <MfaPhoneNumberForm>
      <FormConsumer>
        {/* @ts-expect-error */}
        {({ formIsValid }) => (
          <>
            {isDesktop && (
              <MfaModal
                isShowing
                actionButtons={[
                  <BasicButton
                    key='submit-action'
                    buttonType='submit'
                    disabled={!formIsValid}
                    form='mfaPhoneNumber'
                  >
                    <FormattedMessage
                      defaultMessage='Send code'
                      id='sputnik.PhoneNumberMfaContainer__AfmrGS'
                    />
                  </BasicButton>,
                ]}
                title={title}
              >
                <MfaPhoneNumberField />
              </MfaModal>
            )}
            {!isDesktop && (
              <MfaPopsheet
                isShowing
                actions={[
                  <BasicButton
                    key='submit-action'
                    buttonType='submit'
                    disabled={!formIsValid}
                    form='mfaPhoneNumber'
                  >
                    <FormattedMessage
                      defaultMessage='Send code'
                      id='sputnik.PhoneNumberMfaContainer__AfmrGS'
                    />
                  </BasicButton>,
                ]}
                title={title}
              >
                <MfaPhoneNumberField />
              </MfaPopsheet>
            )}
          </>
        )}
      </FormConsumer>
    </MfaPhoneNumberForm>
  )
}

export default PhoneNumberMfaModalContainer
