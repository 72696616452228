import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { css } from '@emotion/core'
import Tile from '@divvy-web/skylab.tile'
import { PRODUCT_CONST_STRINGS } from '../../resources/constants'
import ImageWrapper from '../ImageWrapper'

// TODO: when adding variable line to this Component, refactor to wrapping this in <Tile /> from skylab instead of custom styles
const TypesOfCreditInfoBox = () => {
  const [makeTestId, getClassName] = useNameFormatter('TypesOfCreditInfoBox')
  return (
    <Tile
      css={wrapperCss}
      dataTestId={makeTestId('')}
    >
      <div className={getClassName('info-wrap')}>
        <div className={getClassName('title')}>
          <FormattedMessage
            defaultMessage='{BILL_SPEND_AND_EXPENSE} offers two different types of credit:'
            id='sputnik.TypesOfCreditInfoBox__3ULLF4'
            values={{ BILL_SPEND_AND_EXPENSE: PRODUCT_CONST_STRINGS.BILL_SPEND_AND_EXPENSE }}
          />
        </div>
        <div
          className={getClassName('standard-credit-container')}
          css={infoBlockCss}
          data-testid={makeTestId('standard-credit-container')}
        >
          <div className={getClassName('image-container')}>
            <ImageWrapper imageName='good-fit-page-standard-credit-line' />
          </div>
          <div className={getClassName('title-and-info-container')}>
            <div className={getClassName('subtitle')}>
              <FormattedMessage
                defaultMessage='Standard credit line'
                id='sputnik.TypesOfCreditInfoBox__WQCeHH'
              />
            </div>
            <div className={getClassName('info')}>
              <FormattedMessage
                defaultMessage='We’ll determine your credit limit based on the info from your credit application.'
                id='sputnik.TypesOfCreditInfoBox__1J9Z2H'
              />
            </div>
          </div>
        </div>
        <div
          className={getClassName('secured-credit-container')}
          css={infoBlockCss}
          data-testid={makeTestId('secured-line-container')}
        >
          <div className={getClassName('image-container')}>
            <ImageWrapper imageName='good-fit-page-secured-credit-line' />
          </div>
          <div className={getClassName('title-and-info-container')}>
            <div className={getClassName('subtitle')}>
              <FormattedMessage
                defaultMessage='{BILL_DIVVY_CARD}'
                id='sputnik.TypesOfCreditInfoBox__Zm8wJX'
                values={{ BILL_DIVVY_CARD: PRODUCT_CONST_STRINGS.BILL_DIVVY_CARD }}
              />
            </div>
            <div className={getClassName('info')}>
              <FormattedMessage
                defaultMessage='Choose your own credit limit via a cash security deposit which you can adjust at any time. '
                id='sputnik.TypesOfCreditInfoBox__JWYBC1'
              />
            </div>
          </div>
        </div>
      </div>
    </Tile>
  )
}

const wrapperCss = ({ mq }) => css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  max-width: 498px;
  max-height: 100%;
  flex-shrink: 0;
  gap: var(--tri-space-1800);
  background: var(--tri-color-fill-accent-primary);
  margin-bottom: var(--tri-space-500);

  .TypesOfCreditInfoBox-wrapper {
    ${mq.xSmallMaxWidth({ margin: '0 0 var(--tri-space-300) 0' })}
  }

  .TypesOfCreditInfoBox-info-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--tri-space-400);
    background: var(--tri-color-text-primary-inverse);
    ${mq.xSmallMaxWidth({ gap: '0' })};
  }
`

const infoBlockCss = ({ mq, type }) => css`
  display: flex;
  align-items: center;

  .TypesOfCreditInfoBox-image-container {
    img {
      max-width: 80px;
      ${mq.xSmallMaxWidth({ maxWidth: '48px' })}
    }
  }

  .TypesOfCreditInfoBox-title {
    ${type.triFontDesktopTitleSmall}
  }

  .TypesOfCreditInfoBox-title-and-info-container {
    .TypesOfCreditInfoBox-subtitle {
      ${type.triFontDesktopBodyMediumEmphasis}
    }

    .TypesOfCreditInfoBox-info {
      ${type.triFontDesktopBodyMedium};
      ${mq.xSmallMaxWidth(type.triFontDesktopBodySmall)}
    }
  }
`

export default TypesOfCreditInfoBox
