import useDidMount from '@divvy-web/hooks.usedidmount'
import { CreditApplication } from '_gql'
import { useAuth } from '../auth'

interface UseApplicantTokenOnMountProps {
  salesforceCreditId: CreditApplication['salesforceCreditId']
}

const useApplicantTokenOnMount = ({ salesforceCreditId }: UseApplicantTokenOnMountProps) => {
  const { appIdFromToken, requestApplicantToken } = useAuth()

  useDidMount(() => {
    if (appIdFromToken() !== salesforceCreditId) requestApplicantToken(salesforceCreditId)
  })
}

export default useApplicantTokenOnMount
