import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { useNavigate, useParams } from 'react-router-dom'
import { CreditApplicationStatus } from '_gql'
import { MAJOR_ACCOUNT_CHANGE, MIGRATION, PATHNAME_CONSTS } from '../../../resources/constants'
import PollingPageContent from '../../../components/PollingPageContent/PollingPageContent'
import { GetDecisionAndStatusForPollingType } from '../SubmittingApplicationPollingPage'

export interface SubmittingApplicationPollingPageContentProps {
  stopPolling: () => void
  decisionAndStatusData: GetDecisionAndStatusForPollingType
  isReadyToNavigate: boolean
  isPreSubmitStatus: boolean
}

const SubmittingApplicationPollingPageContent = ({
  stopPolling,
  decisionAndStatusData,
  isReadyToNavigate,
  isPreSubmitStatus,
}: SubmittingApplicationPollingPageContentProps) => {
  const navigate = useNavigate()
  const { appId } = useParams()
  const appPath = `/app/${appId}/section`
  const { creditApplication, underwritingDeclined, frozenAuthorizedSigner, decisions } = decisionAndStatusData || {}
  const { status, recordType } = creditApplication
  const isMajorAccountChange = recordType === MAJOR_ACCOUNT_CHANGE
  const isMigration = recordType === MIGRATION
  const hasNoDecisions = !decisions || decisions?.length === 0 || decisions?.length > 3
  const hasSingleDecision = decisions?.length === 1
  const hasMultipleDecisions = decisions && decisions?.length >= 2
  const hasSelectedDecision = !!decisions?.find((decision) => decision.userSelectedAt)
  const isRejectedStatus = status === CreditApplicationStatus.REJECTED
  const isMoreInfoNeededStatus = status === CreditApplicationStatus.MORE_INFO_NEEDED

  if (isReadyToNavigate) {
    window.sessionStorage.removeItem('submissionPendingUrl')
    stopPolling()
    switch (true) {
      case isMajorAccountChange:
        navigate(PATHNAME_CONSTS.DASHBOARD_PATH, { replace: true })
        break
      case isMigration:
        navigate(`${appPath}/success`, { replace: true })
        break
      case frozenAuthorizedSigner:
        navigate(`${appPath}/processed${PATHNAME_CONSTS.FROZEN_CREDIT_PATH}`, { replace: true })
        break
      case underwritingDeclined || isRejectedStatus:
        navigate(`${appPath}/processed${PATHNAME_CONSTS.DECLINED_PATH}`, { replace: true })
        break
      case isMoreInfoNeededStatus:
        navigate(PATHNAME_CONSTS.DASHBOARD_PATH, { replace: true })
        break
      case hasNoDecisions:
        navigate(`${appPath}/processed${PATHNAME_CONSTS.MANUAL_REVIEW_PATH}`, { replace: true })
        break
      case hasMultipleDecisions && !hasSelectedDecision:
        navigate(`${appPath}/processed${PATHNAME_CONSTS.MULTI_CREDIT_LINE_OFFER_PATH}`, { replace: false })
        break
      case hasSingleDecision && !hasSelectedDecision:
        navigate(`${appPath}/processed${PATHNAME_CONSTS.SINGLE_CREDIT_LINE_OFFER_PATH}`, { replace: false })
        break
      case isPreSubmitStatus:
        navigate(`/app/${appId}/section/${PATHNAME_CONSTS.SUBMISSION_ERROR_PATH}`, { replace: true })
        break
      default:
        navigate(PATHNAME_CONSTS.DASHBOARD_PATH, { replace: true })
        break
    }
  }

  return (
    <PollingPageContent
      mainText={
        <FormattedMessage
          defaultMessage='{isPreSubmitStatus, select, 
            true {Submitting your application}
            other {Processing your application}
          }'
          id='sputnik.SubmittingApplicationPollingPageContent__S737h6'
          values={{ isPreSubmitStatus }}
        />
      }
      subText={
        <FormattedMessage
          defaultMessage='This could take a couple minutes.'
          id='sputnik.SubmittingApplicationPollingPageContent__zrXnI+'
        />
      }
    />
  )
}

export default SubmittingApplicationPollingPageContent
