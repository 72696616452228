import React from 'react'
import { css } from '@emotion/core'
import { FormattedMessage } from '@divvy-web/i18n'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { useAuth } from '../../auth'
import BillSideBarIcon from '../../components/BillSideBarIcon/BillSideBarIcon'

const SuccessPreApprovedDualOfferSidebar = () => {
  const [getClassName, makeTestId] = useNameFormatter('SuccessPreApprovedDualOfferSidebar')
  const { email: userEmail } = useAuth()

  return (
    <div
      className='one-color-sidebar'
      css={successPreApprovedDualOfferSidebar}
      data-testid={makeTestId('sidebar-container')}
    >
      <BillSideBarIcon />
      <div
        className={getClassName('copyWrapper')}
        data-testid={makeTestId('sidebar-text-copy')}
      >
        <div className={getClassName('sidebarCopyContainer')}>
          <p
            className={getClassName('textBodyTitle')}
            data-testid={makeTestId('sidebar-text-body-title-top')}
          >
            <FormattedMessage
              defaultMessage="What is a 'standard credit line'?"
              id='sputnik.SuccessPreApprovedDualOfferSidebar__9RNpI8'
            />
          </p>
          <p
            className={getClassName('textBodyContent')}
            data-testid={makeTestId('sidebar-text-body-content-top')}
          >
            <FormattedMessage
              defaultMessage='A traditional line of credit with a limit that does not fluctuate. Eligibility and credit limit are based on a number of business factors. The credit limit may be increased over time based on activity, payment history and other factors.'
              id='sputnik.SuccessPreApprovedDualOfferSidebar__+mGenJ'
            />
          </p>
        </div>
        <div className={getClassName('sidebarCopyContainer')}>
          <p
            className={getClassName('textBodyTitle')}
            data-testid={makeTestId('sidebar-text-body-title-middle')}
          >
            <FormattedMessage
              defaultMessage="What is a 'variable credit line'?"
              id='sputnik.SuccessPreApprovedDualOfferSidebar__x83Whl'
            />
          </p>
          <p
            className={getClassName('textBodyContent')}
            data-testid={makeTestId('sidebar-text-body-content-middle')}
          >
            <FormattedMessage
              defaultMessage='This type of credit line features a limit that may increase or decrease over time based on the cash balance of your designated bank account.  We use a secure, digital banking connection to review your account balance(s), determine your limit, and adjust that limit if needed.'
              id='sputnik.SuccessPreApprovedDualOfferSidebar__cpmi2l'
            />
          </p>
        </div>
        <div className={getClassName('sidebarCopyContainer')}>
          <p
            className={getClassName('textBodyTitle')}
            data-testid={makeTestId('sidebar-text-body-title-bottom')}
          >
            <FormattedMessage
              defaultMessage='What will payments look like?'
              id='sputnik.SuccessPreApprovedDualOfferSidebar__4sduHx'
            />
          </p>
          <p
            className={getClassName('textBodyContent')}
            data-testid={makeTestId('sidebar-text-body-content-bottom')}
          >
            <FormattedMessage
              defaultMessage='Your credit line must be paid off in full at the end of each payment cycle.'
              id='sputnik.SuccessPreApprovedDualOfferSidebar__O9h/BV'
            />
          </p>
        </div>
      </div>
      <div className={getClassName('signedInCaption')}>
        <FormattedMessage
          defaultMessage='You are signed in as {userEmail}'
          id='sputnik.SuccessPreApprovedDualOfferSidebar__8qp/TP'
          values={{ userEmail }}
        />
      </div>
    </div>
  )
}

const successPreApprovedDualOfferSidebar = ({ mq, type }) => css`
  background-color: var(--tri-color-fill-accent-primary);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  ${mq.xxSmallMaxWidth({
    display: 'none',
  })}

  .SuccessPreApprovedDualOfferSidebar-copyWrapper {
    ${mq.smallMaxWidth({
      overflow: 'auto',
      padding: '0 var(--tri-space-200)',
    })}
    ${mq.mediumMinWidth({
      padding: '0 var(--tri-space-300)',
    })}
    ${mq.largeMinWidth({
      padding: '0 var(--tri-space-400)',
    })}
    ${mq.xLargeMinWidth({
      padding: '0 var(--tri-space-600)',
    })}
    ${mq.xxLargeMinWidth({
      padding: '0 var(--tri-space-1000)',
    })}
  }

  .SuccessPreApprovedDualOfferSidebar-sidebarCopyContainer {
    background-color: var(--tri-color-fill-primary);
    margin-bottom: var(--tri-space-400);
    border-radius: var(--tri-space-100);
  }

  .SuccessPreApprovedDualOfferSidebar-textBodyTitle {
    ${type.triFontDesktopLabelMediumEmphasis}
    padding: var(--tri-space-300) var(--tri-space-700) var(--tri-space-200) var(--tri-space-500);
  }

  .SuccessPreApprovedDualOfferSidebar-textBodyContent {
    ${type.triFontDesktopCaptionLarge}
    padding: 0 var(--tri-space-500) var(--tri-space-300);
  }

  .SuccessPreApprovedDualOfferSidebar-signedInCaption {
    ${type.triFontDesktopBodySmall}
    text-align: center;
    margin-bottom: var(--tri-space-400);
  }
`

export default SuccessPreApprovedDualOfferSidebar
