/** ! WARNING: DO NOT EDIT ! **
  This file is generated by graphql codegen.
  Make changes to *.graphql documents, not this file
**/

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import type * as _Types from '_gql'
const defaultOptions = {} as const
export type GetDecisionAndStatusForPolling_creditApplication_CreditApplication = {
  readonly __typename: 'CreditApplication'
  readonly salesforceCreditId: string
  readonly status: _Types.CreditApplicationStatus | null
  readonly recordType: string | null
}

export type GetDecisionAndStatusForPolling_decisions_Decision = {
  readonly __typename: 'Decision'
  readonly id: string
  readonly userSelectedAt: any | null
}

export type GetDecisionAndStatusForPolling_Query = {
  readonly __typename: 'Query'
  readonly underwritingDeclined: boolean | null
  readonly frozenAuthorizedSigner: boolean | null
  readonly creditApplication: GetDecisionAndStatusForPolling_creditApplication_CreditApplication | null
  readonly decisions: ReadonlyArray<GetDecisionAndStatusForPolling_decisions_Decision | null> | null
}

export type GetDecisionAndStatusForPollingVariables = _Types.Exact<{
  creditApplicationId: _Types.Scalars['ID']['input']
  companyId: _Types.Scalars['String']['input']
}>

export type GetDecisionAndStatusForPolling = GetDecisionAndStatusForPolling_Query

export const GetDecisionAndStatusForPollingDocument = gql`
  query GetDecisionAndStatusForPolling($creditApplicationId: ID!, $companyId: String!) {
    creditApplication(creditApplicationId: $creditApplicationId) {
      salesforceCreditId
      status
      recordType
    }
    underwritingDeclined(companyId: $companyId)
    frozenAuthorizedSigner(companyId: $companyId)
    decisions(companyId: $companyId) {
      id
      userSelectedAt
    }
  }
`

/**
 * __useGetDecisionAndStatusForPolling__
 *
 * To run a query within a React component, call `useGetDecisionAndStatusForPolling` and pass it any options that fit your needs.
 * When your component renders, `useGetDecisionAndStatusForPolling` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDecisionAndStatusForPolling({
 *   variables: {
 *      creditApplicationId: // value for 'creditApplicationId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetDecisionAndStatusForPolling(
  baseOptions: Apollo.QueryHookOptions<GetDecisionAndStatusForPolling, GetDecisionAndStatusForPollingVariables> &
    ({ variables: GetDecisionAndStatusForPollingVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDecisionAndStatusForPolling, GetDecisionAndStatusForPollingVariables>(
    GetDecisionAndStatusForPollingDocument,
    options,
  )
}
export function useGetDecisionAndStatusForPollingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDecisionAndStatusForPolling, GetDecisionAndStatusForPollingVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDecisionAndStatusForPolling, GetDecisionAndStatusForPollingVariables>(
    GetDecisionAndStatusForPollingDocument,
    options,
  )
}
export function useGetDecisionAndStatusForPollingSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetDecisionAndStatusForPolling,
    GetDecisionAndStatusForPollingVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetDecisionAndStatusForPolling, GetDecisionAndStatusForPollingVariables>(
    GetDecisionAndStatusForPollingDocument,
    options,
  )
}
export type GetDecisionAndStatusForPollingHookResult = ReturnType<typeof useGetDecisionAndStatusForPolling>
export type GetDecisionAndStatusForPollingLazyQueryHookResult = ReturnType<
  typeof useGetDecisionAndStatusForPollingLazyQuery
>
export type GetDecisionAndStatusForPollingSuspenseQueryHookResult = ReturnType<
  typeof useGetDecisionAndStatusForPollingSuspenseQuery
>
export type GetDecisionAndStatusForPollingQueryResult = Apollo.QueryResult<
  GetDecisionAndStatusForPolling,
  GetDecisionAndStatusForPollingVariables
>
