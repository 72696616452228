import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { css } from '@emotion/core'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import Modal from '@divvy-web/skylab.modal'

interface ResendCodeModalProps {
  showModal: boolean
  contact: string
  isSubmitting: boolean
  handleResendCode: () => void
  hideModal: () => void
}

const ResendAccessCodeModal = ({
  showModal,
  contact,
  isSubmitting,
  handleResendCode,
  hideModal,
}: ResendCodeModalProps) => {
  return (
    <Modal
      className='resend-code fs-unmask'
      css={resendCodeModalCss}
      dataTestId='resend-code'
      isShowing={showModal}
      title={
        <FormattedMessage
          defaultMessage='Resend verification code'
          id='sputnik.ResendAccessCodeModal__62beOi'
        />
      }
    >
      <FormattedMessage
        defaultMessage='Resend code to {contact}?'
        id='sputnik.ResendCodeModalk__SCepFg'
        values={{ contact }}
      />
      <div className='resend-code-btn-container'>
        <BasicButton
          className='btn-resendcode-cancel'
          color='neutral'
          dataTestId='resend-code-cancel-button'
          disabled={isSubmitting}
          type={BASIC_BUTTON_TYPE_FLAT}
          onClick={hideModal}
        >
          <FormattedMessage
            defaultMessage='Cancel'
            id='sputnik.ResendAccessCodeModal__47FYwb'
          />
        </BasicButton>
        <BasicButton
          className='btn-resendcode'
          dataTestId='resend-code-button'
          disabled={isSubmitting}
          showSpinner={isSubmitting}
          onClick={handleResendCode}
        >
          <FormattedMessage
            defaultMessage='Resend'
            id='sputnik.ResendAccessCodeModal__IXycMo'
          />
        </BasicButton>
      </div>
    </Modal>
  )
}

const resendCodeModalCss = ({ mq }) => css`
  .resend-code-main {
    max-width: 544px;
    ${mq.xSmallMaxWidth({ margin: 'var(--tri-space-200)' })};
  }

  .resend-code-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--tri-space-750);
  }

  .btn-resendcode-cancel {
    margin-right: var(--tri-space-400);
  }

  .Modal {
    &-header {
      ${mq.xSmallMaxWidth({
        padding: 'var(--tri-space-200) var(--tri-space-300) 0 var(--tri-space-300)',
      })};
    }

    &-content {
      padding: var(--tri-space-300) var(--tri-space-600);
      ${mq.xSmallMaxWidth({ padding: 'var(--tri-space-300)' })};
    }
  }
`

export default ResendAccessCodeModal
