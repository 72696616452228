import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import { LogoutButton } from '../../components'
import GoToDashboardButton from '../../components/GoToDashboardButton'
import CustomerAssistanceButton from '../../components/CustomerAssistanceButton/CustomerAssistanceButton'
import ImageWrapper from '../../components/ImageWrapper'
import OneColorSidebar from '../../components/OneColorSidebar/OneColorSidebar'
import { successWorkingOutDetailsContainerCss } from './SuccessWorkingOutDetailsStyles'

const SuccessWorkingOutDetails = () => {
  const [getClassName, makeTestId] = useNameFormatter('SuccessWorkingOutDetails')
  const { isMobile } = useDeviceDetect()

  const officeDeskLadyPath = 'https://app.divvy.co/assets/illustrations/office-desk-07b-01'
  const officeDeskLadySrcSet = `${officeDeskLadyPath}@3x.png, ${officeDeskLadyPath}@2x.png, ${officeDeskLadyPath}@1x.png`

  return (
    <>
      <div
        className='fs-unmask'
        css={successWorkingOutDetailsContainerCss}
        data-testid={makeTestId('')}
      >
        <CustomerAssistanceButton />
        <div className={getClassName('body-content')}>
          <div
            className={getClassName('body-text-content')}
            data-testid={makeTestId('body-text-content')}
          >
            {isMobile && (
              <ImageWrapper
                alt='person-at-office-desk'
                imageName='manual-review-application-mobile'
              />
            )}
            <div
              className={getClassName('body-title')}
              data-testid={makeTestId('please-stand-by')}
            >
              <FormattedMessage
                defaultMessage="We're still processing your application"
                id='sputnik.SuccessWorkingOutDetails__334LMo'
              />
            </div>
            <div
              className={getClassName('body-text')}
              data-testid={makeTestId('we-could-not-auto-approve')}
            >
              <FormattedMessage
                defaultMessage='We need to check a few more things before providing an offer. Visit the dashboard for updates on your offer status. We’ll also contact you via email when you have an offer ready for review.'
                id='sputnik.SuccessWorkingOutDetails__kgsS1u'
              />
            </div>
            <div className={getClassName('button-container')}>
              <GoToDashboardButton componentName={makeTestId('')} />
              <LogoutButton buttonType={BASIC_BUTTON_TYPE_OUTLINED} />
            </div>
          </div>
        </div>
        {!isMobile && <OneColorSidebar srcImage={officeDeskLadySrcSet} />}
      </div>
    </>
  )
}

export default SuccessWorkingOutDetails
