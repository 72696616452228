import React from 'react'
import { css } from '@emotion/core'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import BillSideBarIcon from '../BillSideBarIcon/BillSideBarIcon'

interface OneColorSidebarProps {
  className?: string
  srcImage: string
}
const OneColorSidebar = ({ className, srcImage }: OneColorSidebarProps) => {
  const makeTestId = useMakeTestId('OneColorSidebar')
  return (
    <div
      className='one-color-sidebar'
      css={oneColorSidebar}
    >
      <img
        alt='sidebar.png'
        className={`${className || 'sidebar-image'}`}
        data-testid={makeTestId('sidebar-image')}
        srcSet={srcImage}
      />
      <BillSideBarIcon />
    </div>
  )
}

const oneColorSidebar = ({ mq }) => css`
  background-color: var(--tri-color-fill-accent-primary);
  display: flex;
  flex-direction: column;
  flex: 1;
  ${mq.xxSmallMaxWidth({
    display: 'none',
  })}
`

export default OneColorSidebar
