import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'
import { useNavigate } from 'react-router-dom'
import PollingPageContent from '../../../components/PollingPageContent/PollingPageContent'
import { PATHNAME_CONSTS } from '../../../resources/constants'

interface AcceptingOfferPollingPageContentProps {
  isReadyToNavigate: boolean
  stopPolling: () => void
}
const AcceptingOfferPollingPageContent = ({
  isReadyToNavigate,
  stopPolling,
}: AcceptingOfferPollingPageContentProps) => {
  const navigate = useNavigate()
  const showSuccessToast = useToast(TOAST_TYPE_SUCCESS) as any

  if (isReadyToNavigate) {
    stopPolling()
    navigate(PATHNAME_CONSTS.DASHBOARD_PATH, { replace: true })
    showSuccessToast(
      <FormattedMessage
        defaultMessage='Offer accepted'
        id='sputnik.AcceptingOfferPollingPageContent__IB0Vm6'
      />,
      {
        autoHideDelay: 5000,
      },
    )
  }

  return (
    <PollingPageContent
      mainText={
        <FormattedMessage
          defaultMessage="Hang tight! We're processing your acceptance."
          id='sputnik.AcceptingOfferPollingPageContent__TnYOAx'
        />
      }
      subText={
        <FormattedMessage
          defaultMessage='This can take a minute or two. Please do not close this page.'
          id='sputnik.AcceptingOfferPollingPageContent__WJQRQZ'
        />
      }
    />
  )
}

export default AcceptingOfferPollingPageContent
