import React, { useState } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { useToast, TOAST_TYPE_DANGER } from '@divvy-web/skylab.toast'
import { css } from '@emotion/core'
import { useLocation, useNavigate } from 'react-router-dom'
import { logInfo } from '../../utils/loggerUtils'
import AccessCodeForm from '../AccessCodeForm'
import ResendAccessCodeModal from '../ResendAccessCodeModal/ResendAccessCodeModal'
import AccessCodeField from '../AccessCodeField/AccessCodeField'
import FormButtons from '../FormButtons'
import SmsAccessCodeMfaModalContent from '../SmsAccessCodeMfaModalContent'

interface SmsAccessCodeFormProps {
  isModal?: boolean
}

const SmsAccessCodeForm = ({ isModal = false }: SmsAccessCodeFormProps) => {
  const [hasError, setHasError] = useState(false)
  const navigate = useNavigate()
  const { state } = useLocation()
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showResendModal, setShowResendModal] = useState(false)
  const hideModal = () => setShowResendModal(false)

  const resendCode = () => {
    setIsSubmitting(true)

    logInfo({
      attributes: {
        action: 'handleResendCode',
        result: 'Resend auth code clicked',
        mfaMethod: 'sms',
      },
      eventName: 'SignIn',
    })

    hideModal()
    showDangerToast(
      <FormattedMessage
        defaultMessage='Oops... Something went wrong. Please try again.'
        id='sputnik.SmsAccessCodeForm__5+8dzT'
      />,
    )
    setIsSubmitting(false)
  }

  const handleSubmitAccessCode = (_accessCode: string) => {
    setIsSubmitting(true)

    logInfo({
      attributes: {
        action: 'handleSubmitAccessCode',
        result: 'Verify code button clicked',
        mfaMethod: 'sms',
      },
      eventName: 'SignIn',
    })

    //TODO: Actual submit call here
    setIsSubmitting(false)
    setHasError(true)
  }

  const handleResendClick = isModal ? resendCode : () => setShowResendModal(true)
  const handlePreviousClick = () => navigate(-1)

  const phoneNumber = state?.phoneNumber
  const phoneLastFour = phoneNumber?.slice(-4)

  const processDescirption = (
    <FormattedMessage
      defaultMessage='We sent a code to your phone number ending with {phoneLastFour} to make sure it’s you.'
      id='sputnik.SmsAccessCodeForm__yg/OcB'
      values={{ phoneLastFour }}
    />
  )

  return (
    <>
      <AccessCodeForm
        disableError={() => setHasError(false)}
        handleSubmitAccessCode={handleSubmitAccessCode}
      >
        {isModal && (
          <SmsAccessCodeMfaModalContent
            handlePreviousClick={handlePreviousClick}
            handleResendClick={handleResendClick}
            hasError={hasError}
            processDescription={processDescirption}
          />
        )}
        {!isModal && (
          <>
            <AccessCodeField
              handleResendClick={handleResendClick}
              hasError={hasError}
              processDescription={processDescirption}
            />
            <div
              className='verify-code-form-button-wrapper'
              css={formButtonCss}
            >
              <FormButtons
                disableSaveOnInvalid
                hideExit
                disableNext={isSubmitting}
                handlePreviousClick={handlePreviousClick}
                nextButtonType='submit'
                showNextSpinner={isSubmitting}
              />
            </div>
          </>
        )}
      </AccessCodeForm>
      {!isModal && (
        <ResendAccessCodeModal
          contact={phoneNumber}
          handleResendCode={resendCode}
          hideModal={hideModal}
          isSubmitting={isSubmitting}
          showModal={showResendModal}
        />
      )}
    </>
  )
}

const formButtonCss = css`
  position: relative;
`

export default SmsAccessCodeForm
