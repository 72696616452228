import React, { useContext, useState } from 'react'
import { createSearchParams, URLSearchParamsInit, useNavigate, useParams, useLocation } from 'react-router-dom'
import { FormattedMessage } from '@divvy-web/i18n'
import BasicButton, { BASIC_BUTTON_TYPE_FLAT } from '@divvy-web/skylab.basicbutton'
import TextLink from '@divvy-web/skylab.textlink'
import { TOAST_TYPE_DANGER, useToast } from '@divvy-web/skylab.toast'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import Spinner from '@divvy-web/skylab.spinner'
import { useCanary } from '@bill/canary.react'
import GoToDashboardButton from '../../../components/GoToDashboardButton'
import { logError, logInfo } from '../../../utils/loggerUtils'
import ApplicationAgreements from '../../../components/ApplicationAgreements'
import { ApplicationAgreementsProps } from '../../../components/ApplicationAgreements/ApplicationAgreements'
import { useSelectedDecision } from '../../gql/mutations/CreditLineOfferSelectedDecision.gql'
import CustomerAssistanceButton from '../../../components/CustomerAssistanceButton'
import { PATHNAME_CONSTS } from '../../../resources/constants'
import OneColorSidebar from '../../../components/OneColorSidebar'
import ImageWrapper from '../../../components/ImageWrapper'
import useDeviceDetect from '../../../hooks/useDeviceDetect'
import { DecisionContext } from '../DecisionsContext'
import { useGetCreditApplicationTilaInfo } from '../../gql/GetCreditApplicationTilaInfo.gql'
import { getSideBarImage } from '../applicationProcessedUtils'
import { securedCreditLineOfferAcceptanceCss } from './securedCreditLineOfferAcceptanceStyles'

const SecuredCreditLineOfferAcceptance = () => {
  const navigate = useNavigate()
  const { appId } = useParams()
  const { pathname } = useLocation()
  const { isMobile } = useDeviceDetect()
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any
  const shouldShowNewPollingLogic = useCanary('show-new-polling-logic')
  const [getClassName, makeTestId] = useNameFormatter('SecuredCreditLineOfferAcceptance')
  const { securedLineOffer, isLoggedInUserAuthorizedSigner, authSignerEmail } = useContext(DecisionContext)
  const { id: decisionId, offerType } = securedLineOffer || {}
  const { data, loading: isLoadingTilaInfo } = useGetCreditApplicationTilaInfo({
    fetchPolicy: 'network-only',
    variables: {
      creditApplicationId: appId as string,
      underwritingDecisionUuid: decisionId,
      underwritingOfferType: offerType,
      skipTila: !isLoggedInUserAuthorizedSigner,
    },
    // we don't need any of this information if the user is not the authorized signer
    skip: !securedLineOffer || !isLoggedInUserAuthorizedSigner || !appId,
  })

  const [selectedDecision, { loading: isSelectedDecisionLoading }] = useSelectedDecision({
    onCompleted: () => {
      logInfo({
        attributes: {
          action: 'selectedDecision',
          result: shouldShowNewPollingLogic
            ? `accepts offer for secured credit line and send to polling (/app/${appId}/section/${PATHNAME_CONSTS.ACCEPTING_OFFER})`
            : `accepts offer for secured credit line and send to polling (/app/${appId}/section/processing-application)`,
        },
        eventName: 'SecuredCreditLineOfferAcceptance',
      })

      if (!shouldShowNewPollingLogic) {
        navigate({
          pathname: `/app/${appId}/section/processing-application`,
          search: createSearchParams({
            pollingForStatusSince: new Date().getTime(),
            isPollingAfterOfferAcceptance: true,
          } as unknown as URLSearchParamsInit).toString(),
        })
      }

      if (shouldShowNewPollingLogic) {
        navigate(`/app/${appId}/section/${PATHNAME_CONSTS.ACCEPTING_OFFER}`)
      }
    },
    onError: (e) => {
      logError({
        attributes: {
          action: 'selectedDecision',
          message: e?.message,
          result: 'Error accepting offer for secured credit line',
        },
        eventName: 'SecuredCreditLineOfferAcceptance',
      })

      showDangerToast(
        <FormattedMessage
          defaultMessage='There was an error accepting your offer.'
          id='sputnik.SecuredCreditLineOfferAcceptance__oRIVFo'
        />,
        {
          autoHideDelay: 5000,
        },
      )
    },
  })

  const isSingleOffer = !pathname.includes('credit-line-offers')

  // for ApplicationAgreements
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)

  const creditApplication = (data?.creditApplication as ApplicationAgreementsProps['creditApplication']) || {}
  const tila: { content?: string; meta?: { signedAt?: string } } = creditApplication?.tila || {}

  const isLoading = isLoadingTilaInfo || isSelectedDecisionLoading
  const hasTilaContent = !!tila?.content
  const hasAgreedToTila = !!tila?.meta?.signedAt
  const disabledWithTila = (!hasAgreedToTerms ||
    isLoading ||
    (hasTilaContent && !hasAgreedToTila) ||
    !creditApplication) as boolean
  const disabledWithoutTila = (!hasAgreedToTerms || isLoading) as boolean
  const disableAcceptOfferButton = hasTilaContent ? disabledWithTila : disabledWithoutTila

  const handleCheckboxClick = () => {
    setHasAgreedToTerms(!hasAgreedToTerms)
  }

  const handleAcceptClick = () => {
    selectedDecision({
      variables: {
        id: decisionId,
        salesforceCreditId: appId as string,
      },
    })
  }

  if (isLoadingTilaInfo || !securedLineOffer) {
    return (
      <div css={securedCreditLineOfferAcceptanceCss}>
        <Spinner centered />
      </div>
    )
  }

  return (
    <div css={securedCreditLineOfferAcceptanceCss}>
      <CustomerAssistanceButton />
      <div className='main-container fs-unmask'>
        <div className={getClassName('page-content')}>
          <div
            className={getClassName('body')}
            data-testid={makeTestId('')}
          >
            <div className={getClassName('title')}>
              <FormattedMessage
                defaultMessage='Your secured credit line'
                id='sputnik.SecuredCreditLineOfferAcceptance__BYoPgD'
              />
            </div>
            {isMobile && (
              <div>
                <ImageWrapper
                  alt='person scanning mobile device'
                  // @ts-expect-error component ImageWrapper is missing className as a prop, but is needed here for styling purposes
                  className={getClassName('mobile-secured-offer-image')}
                  imageName='credit-line-offer-mobile'
                />
              </div>
            )}
            <div className={getClassName('how-it-works')}>
              <FormattedMessage
                defaultMessage='How it works:'
                id='sputnik.SecuredCreditLineOfferAcceptance__ON3IdR'
              />
            </div>
            <div className={getClassName('how-it-works-list')}>
              <ul>
                <li>
                  <FormattedMessage
                    defaultMessage='Your credit limit is funded by a cash security deposit, which is adjustable and refundable based on your balance.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__STt4ih'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='Your balance is due in full at the end of each billing cycle.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__8LBAFn'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='Make payments using your cash security deposit or another funding source.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__nKd0hF'
                  />
                </li>
                <li>
                  <FormattedMessage
                    defaultMessage='Earn 1.5% cash back rewards on all eligible purchases.'
                    id='sputnik.SecuredCreditLineOfferAcceptance__o2g3F3'
                  />
                </li>
              </ul>
            </div>
            <p className={getClassName('learn-more-wrapper')}>
              <FormattedMessage
                defaultMessage='Still need more info? <textlink>Learn more about secured credit lines</textlink>'
                id='sputnik.SecuredCreditLineOfferAcceptance__N5sAdA'
                values={{
                  textlink: (chunks) => (
                    <TextLink
                      className={getClassName('learn-more')}
                      href='https://billcom.wistia.com/medias/yv1t6k8wpk'
                      target='_blank'
                    >
                      {chunks}
                    </TextLink>
                  ),
                }}
              />
            </p>
            {isLoggedInUserAuthorizedSigner && offerType && (
              <ApplicationAgreements
                creditApplication={creditApplication}
                decisionId={decisionId}
                // TODO: change this variable name to hasAgreedToTila down the chain
                hasAgreedToOfferSummary={hasAgreedToTila}
                hasAgreedToTerms={hasAgreedToTerms}
                offerType={offerType}
                onCheckboxClick={handleCheckboxClick}
              />
            )}
            {!isLoggedInUserAuthorizedSigner && (
              <div className={getClassName('unauthorized-accept-message')}>
                <FormattedMessage
                  defaultMessage='Only the authorized signer ({email}) can accept this offer.'
                  id='sputnik.SecuredCreditLineOfferAcceptance__igv+K/'
                  values={{
                    email: authSignerEmail,
                  }}
                />
              </div>
            )}
            <section className={getClassName('buttons')}>
              {!isSingleOffer && (
                <BasicButton
                  className={getClassName('previous')}
                  dataTestId={makeTestId('previous')}
                  type={BASIC_BUTTON_TYPE_FLAT}
                  onClick={() => navigate(-1)}
                >
                  <FormattedMessage
                    defaultMessage='Previous'
                    id='sputnik.SecuredCreditLineOfferAcceptance__JJNc3c'
                  />
                </BasicButton>
              )}
              {isSingleOffer && (
                <GoToDashboardButton
                  buttonType={BASIC_BUTTON_TYPE_FLAT}
                  componentName={makeTestId('')}
                />
              )}
              {isLoggedInUserAuthorizedSigner && (
                <BasicButton
                  className={getClassName('accept')}
                  dataTestId={makeTestId('accept')}
                  disabled={disableAcceptOfferButton}
                  showSpinner={isSelectedDecisionLoading}
                  onClick={handleAcceptClick}
                >
                  <FormattedMessage
                    defaultMessage='Accept offer'
                    id='sputnik.SecuredCreditLineOfferAcceptance__/xro5W'
                  />
                </BasicButton>
              )}
            </section>
          </div>
        </div>
      </div>
      {!isMobile && <OneColorSidebar srcImage={getSideBarImage()} />}
    </div>
  )
}

export default SecuredCreditLineOfferAcceptance
