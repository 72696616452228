import React, { useState } from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import { useToast, TOAST_TYPE_DANGER, TOAST_TYPE_SUCCESS } from '@divvy-web/skylab.toast'
import { css } from '@emotion/core'
import { useLocation, useNavigate } from 'react-router-dom'
import { logInfo } from '../../utils/loggerUtils'
import { PATHNAME_CONSTS } from '../../resources/constants'
import { clearAllLocalStorageValues, useAuth } from '../../auth'
import AccessCodeForm from '../AccessCodeForm'
import ResendAccessCodeModal from '../ResendAccessCodeModal/ResendAccessCodeModal'
import AccessCodeField from '../AccessCodeField/AccessCodeField'
import FormButtons from '../FormButtons'

const EmailAccessCodeForm = () => {
  const [hasError, setHasError] = useState(false)
  const navigate = useNavigate()
  const { email, requestAuthCode, verifyAuthCode } = useAuth()
  const { search, state } = useLocation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const showSuccessToast = useToast(TOAST_TYPE_SUCCESS) as any
  const showDangerToast = useToast(TOAST_TYPE_DANGER) as any

  const [showResendModal, setShowResendModal] = useState(false)
  const hideModal = () => setShowResendModal(false)

  const handleSubmitAccessCode = (accessCode: string) => {
    setIsSubmitting(true)

    logInfo({
      attributes: {
        action: 'handleSubmitAccessCode',
        result: 'Verify code button clicked',
        mfaMethod: 'email',
      },
      eventName: 'SignIn',
    })

    verifyAuthCode(accessCode.trim(), null, state?.referrer, search).catch(() => setHasError(true))

    setIsSubmitting(false)
  }

  const handleResendCode = () => {
    setIsSubmitting(true)

    logInfo({
      attributes: {
        action: 'handleResendCode',
        result: 'Resend auth code clicked',
        mfaMethod: 'email',
      },
      eventName: 'SignIn',
    })
    requestAuthCode(email)
      .then(() => {
        hideModal()
        showSuccessToast(
          <FormattedMessage
            defaultMessage='A new code was sent to {email}.'
            id='sputnik.EmailAccessCodeForm__JbOBex'
            values={{ email }}
          />,
        )
      })
      .catch(() =>
        showDangerToast(
          <FormattedMessage
            defaultMessage='Oops... Something went wrong. Please try again.'
            id='sputnik.EmailAccessCodeForm__5+8dzT'
          />,
        ),
      )

    setIsSubmitting(false)
  }

  const handlePreviousClick = () => {
    clearAllLocalStorageValues()
    navigate(
      {
        pathname: PATHNAME_CONSTS.AUTH_PATH,
        search,
      },
      { state },
    )
  }

  return (
    <>
      <AccessCodeForm
        disableError={() => setHasError(false)}
        handleSubmitAccessCode={handleSubmitAccessCode}
      >
        <AccessCodeField
          handleResendClick={() => setShowResendModal(true)}
          hasError={hasError}
          processDescription={
            <FormattedMessage
              defaultMessage='An email was sent to {email}. It may take a few minutes to receive the code. The code will expire in 15 minutes.'
              id='sputnik.EmailAccessCodeForm__zTLW0I'
              values={{ email }}
            />
          }
        />
        <div
          className='verify-code-form-button-wrapper'
          css={formButtonCss}
        >
          <FormButtons
            disableSaveOnInvalid
            hideExit
            disableNext={isSubmitting || hasError}
            handlePreviousClick={handlePreviousClick}
            nextButtonType='submit'
            showNextSpinner={isSubmitting}
          />
        </div>
      </AccessCodeForm>
      <ResendAccessCodeModal
        contact={email}
        handleResendCode={handleResendCode}
        hideModal={hideModal}
        isSubmitting={isSubmitting}
        showModal={showResendModal}
      />
    </>
  )
}
const formButtonCss = css`
  position: relative;
`

export default EmailAccessCodeForm
