import React, { ReactNode } from 'react'
import { css } from '@emotion/core'
import Modal from '@divvy-web/skylab.modal'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import ImageWrapper from '../ImageWrapper'

interface MfaModalProps {
  isShowing: boolean
  children: ReactNode
  title: ReactNode
  actionButtons: ReactNode[]
}

const MfaModal = ({ isShowing, actionButtons, children, title }: MfaModalProps) => {
  const [getClassName] = useNameFormatter('MfaModal')

  return (
    <Modal
      actions={actionButtons}
      className={getClassName('')}
      css={mfaModalStyles}
      headerColor='accent'
      headerImage={
        <ImageWrapper
          alt='password-lock'
          imageName='signup-create-password-modal'
        />
      }
      isShowing={isShowing}
      title={title}
    >
      {children}
    </Modal>
  )
}

const mfaModalStyles = css`
  .MfaModal {
    &-main {
      width: 700px;
    }
  }

  .Modal-header .MfaModal-header-text {
    justify-content: center;
  }
`

export default MfaModal
