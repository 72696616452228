import React, { ReactNode } from 'react'
import Form from '@divvy-web/skylab.form'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { useNavigate, createSearchParams, URLSearchParamsInit, useLocation } from 'react-router-dom'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { logInfo } from '../../utils/loggerUtils'
import { PATHNAME_CONSTS } from '../../resources/constants'

interface MfaPhoneNumberFormProps {
  children: ReactNode
}

const MfaPhoneNumberForm = ({ children }: MfaPhoneNumberFormProps) => {
  const [getClassName, makeTestId] = useNameFormatter('MfaPhoneNumberForm')
  const { pathname } = useLocation()

  const validation = {
    mfaPhoneNumber: {
      presence: {
        message: 'Please enter a phone number',
      },
      type: {
        message: 'Please enter a valid phone number',
        type: (val: string) => isValidPhoneNumber(val || ''),
      },
    },
  }

  const navigate = useNavigate()

  const handleSendSmsCode = (mfaPhoneNumber: string) => {
    logInfo({
      attributes: {
        action: 'handleSendSmsCode',
        result: 'Send code button has been clicked',
      },
      eventName: 'MfaVerification',
    })

    if (pathname === PATHNAME_CONSTS.MFA_VERIFICATION) {
      navigate(
        {
          pathname: PATHNAME_CONSTS.VERIFY_CODE_PATH,
          search: createSearchParams({ referrer: PATHNAME_CONSTS.MFA_VERIFICATION }).toString(),
        },
        { state: { phoneNumber: mfaPhoneNumber } },
      )
    } else {
      navigate(
        {
          pathname,
          search: createSearchParams({ verifyAccessCode: true } as unknown as URLSearchParamsInit).toString(),
        },
        { state: { phoneNumber: mfaPhoneNumber } },
      )
    }
  }

  return (
    <>
      <Form
        className={getClassName('form')}
        dataTestId={makeTestId('form')}
        id='mfaPhoneNumber'
        validation={validation}
        onSubmit={(_, { mfaPhoneNumber }) => handleSendSmsCode(mfaPhoneNumber)}
      >
        {children}
      </Form>
    </>
  )
}
export default MfaPhoneNumberForm
