import { css } from '@emotion/core'

export const statusTileContentWebCss = ({ type }) => css`
  .StatusTileWebView-business-name {
    ${type.triFontDesktopTitleMediumEmphasis}
    margin-bottom: var(--tri-space-150);
  }

  .Tile-content {
    padding-bottom: var(--tri-space-450);
  }

  .StatusTileWebView-content {
    display: flex;
    gap: var(--tri-space-400);
  }

  .StatusTileWebView-main-title {
    margin-top: var(--tri-space-300);
    ${type.triFontDesktopTitleMediumEmphasis}
  }

  .StatusTileWebView-warningColor {
    margin-left: var(--tri-space-200);
    margin-bottom: var(--tri-space-75);
  }

  .StatusTileWebView-image > img {
    max-width: 150px;
  }

  .status-sub-header,
  .status-error-text {
    margin-top: var(--tri-space-100);
    margin-bottom: var(--tri-space-100);
    ${type.triFontDesktopBodyLarge}
  }

  .status-sub-header {
    color: var(--tri-color-text-secondary);
  }

  .status-error-text {
    color: var(--tri-color-text-danger);
  }

  .StatusTileWebView-description {
    ${type.triFontDesktopBodyMedium}

    ul {
      margin-top: var(--tri-space-200);
      margin-left: var(--tri-space-350);
      margin-bottom: var(--tri-space-300);
    }
  }

  .StatusTileWebView-button-container {
    display: flex;
    gap: var(--tri-space-200);

    .StatusButtons {
      min-width: 0;
      gap: var(--tri-space-200);

      button {
        margin: var(--tri-space-50) 0;
        max-width: 240px;
      }
    }
  }
`
export const statusTileContentMobileCss = ({ type }) => css`
  .StatusTileMobileView-business-name {
    margin-bottom: var(--tri-space-150);
    ${type.triFontDesktopTitleSmallEmphasis}
  }

  .Tile-content {
    padding-top: var(--tri-space-200);
  }

  .StatusTileMobileView-content {
    display: flex;
    flex-direction: column;
  }

  .StatusTileMobileView-info-container {
    display: flex;
    justify-content: space-between;
  }

  .StatusTileMobileView-main-title {
    margin-top: var(--tri-space-100);
    ${type.triFontDesktopBodyMediumEmphasis}
  }

  .StatusTileMobileView-warningColor {
    margin-left: var(--tri-space-150);
    margin-bottom: var(--tri-space-50);
  }

  .StatusTileMobileView-image > img {
    max-width: 96px;
    transform: scaleX(-1);
  }

  .status-sub-header,
  .status-error-text {
    margin-bottom: var(--tri-space-100);
    ${type.triFontDesktopBodySmall}
    overflow-x: visible;
  }

  .status-sub-header {
    color: var(--tri-color-text-primary);
  }

  .status-error-text {
    color: var(--tri-color-text-danger);
  }

  .StatusTileMobileView-description {
    ${type.triFontDesktopLabelXSmall}
    color: var(--tri-color-text-secondary);
    margin-top: var(--tri-space-100);

    ul {
      margin-top: var(--tri-space-100);
      margin-left: var(--tri-space-350);
    }
  }

  .StatusTileMobileView-button-container {
    display: flex;
    justify-content: center;
    gap: var(--tri-space-200);

    .StatusButtons {
      flex-direction: column;
      min-width: 0;
      gap: var(--tri-space-200);

      button {
        margin: var(--tri-space-50) 0;
        max-width: 240px;
      }
    }
  }
`
