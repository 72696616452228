import React, { ReactNode } from 'react'
import BasicButton, { BASIC_BUTTON_TYPE_OUTLINED } from '@divvy-web/skylab.basicbutton'
import { FormattedMessage } from '@divvy-web/i18n'
import { useForm } from '@divvy-web/skylab.form'
import MfaModal from '../MfaModal/MfaModal'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import MfaPopsheet from '../MfaPopsheet/MfaPopsheet'
import AccessCodeField from '../AccessCodeField/AccessCodeField'

interface SmsAccessCodeMfaModalContentProps {
  hasError: boolean
  processDescription: ReactNode
  handleResendClick: () => void
  handlePreviousClick: () => void
}

type UseForm = () => Record<'formIsValid', boolean>

const SmsAccessCodeMfaModalContent = ({
  hasError,
  handlePreviousClick,
  handleResendClick,
  processDescription,
}: SmsAccessCodeMfaModalContentProps) => {
  const { isDesktop } = useDeviceDetect()
  const { formIsValid } = (useForm as unknown as UseForm)()

  const title = (
    <>
      <FormattedMessage
        defaultMessage='Enter your verification code'
        id='sputnik.SmsAccesCodeMfaModalContent__C5F7en'
      />
    </>
  )

  const actionButtons = [
    <BasicButton
      key='submit-action'
      type={BASIC_BUTTON_TYPE_OUTLINED}
      onClick={handlePreviousClick}
    >
      <FormattedMessage
        defaultMessage='Try another number'
        id='sputnik.SmsAccessCodeMfaModalContent__AfmrGS'
      />
    </BasicButton>,
    <BasicButton
      key='submit-action'
      buttonType='submit'
      disabled={!formIsValid}
      form='mfaPhoneNumber'
    >
      <FormattedMessage
        defaultMessage='Verify'
        id='sputnik.SmsAccessCodeMfaModalContent__q5Xl0M'
      />
    </BasicButton>,
  ]

  return (
    <>
      {isDesktop && (
        <MfaModal
          isShowing
          actionButtons={actionButtons}
          title={title}
        >
          <AccessCodeField
            handleResendClick={handleResendClick}
            hasError={hasError}
            processDescription={processDescription}
          />
        </MfaModal>
      )}
      {!isDesktop && (
        <MfaPopsheet
          isShowing
          actions={actionButtons}
          title={title}
        >
          <AccessCodeField
            handleResendClick={handleResendClick}
            hasError={hasError}
            processDescription={processDescription}
          />
        </MfaPopsheet>
      )}
    </>
  )
}

export default SmsAccessCodeMfaModalContent
