import React, { useState, useEffect, useCallback, ReactNode } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useCanary } from '@bill/canary.react'
import { FormattedMessage } from '@divvy-web/i18n'
import { TOAST_TYPE_SUCCESS, useToast } from '@divvy-web/skylab.toast'
import { CreditApplicationStatus } from '_gql'
import GetDecisions from '../../pages/gql/GetDecisions.gql'
import GetApplicationStatus from '../../pages/gql/GetApplicationStatus.gql'
import useDivvyUUID from '../../hooks/useDivvyUUID'
import ProcessingApplication from '../ProcessingApplication'
import { PATHNAME_CONSTS } from '../../resources/constants'
import useApplicantTokenOnMount from '../../hooks/useApplicantTokenOnMount'

const CREDIT_DECISION_POLLING_MAX_TIMEOUT = window.__DivvyEnvironment.PROCESSING_MAX_TIMEOUT || 500
const STATUS_POLLING_MAX_TIMEOUT = 60000
const POLLING_RATE = 3000

const calculateStatusTimeoutRemaining = (pollingForStatusSince) =>
  STATUS_POLLING_MAX_TIMEOUT - (new Date().getTime() - pollingForStatusSince)

const RocketAnimation = () => {
  /* make sure we have a token before we start polling */
  const { appId } = useParams()
  useApplicantTokenOnMount({ salesforceCreditId: appId || '' })
  const navigate = useNavigate()
  const showSuccessToast = useToast(TOAST_TYPE_SUCCESS) as any
  const { pathname, search: urlSearch } = useLocation()
  const showSecuredLine = useCanary('show-secured-line')
  const appPath = `/app/${appId}/section/processed`
  const [search, setSearch] = useSearchParams()
  const isMajorAccountChange = search?.get('isMajorAccountChange') === 'true'
  const pollingForStatusSince = search?.get('pollingForStatusSince')
    ? parseInt(search?.get('pollingForStatusSince') as string)
    : null

  const [companyDivvyUuid] = useDivvyUUID()
  const [creditDecisionTimer, setCreditDecisionTimer] = useState(0)
  const [statusTimer, setStatusTimer] = useState(0)

  const {
    data: decisionsData,
    startPolling: startPollingForCreditDecision,
    stopPolling: stopPollingForCreditDecision,
  } = useQuery(GetDecisions, {
    fetchPolicy: 'no-cache',
    skip: !companyDivvyUuid || !!pollingForStatusSince,
    variables: { companyId: companyDivvyUuid },
  })

  const {
    data: statusData,
    loading: isLoadingStatusData,
    startPolling: startPollingForStatus,
    stopPolling: stopPollingForStatus,
  } = useQuery(GetApplicationStatus, {
    fetchPolicy: 'no-cache',
    variables: { creditApplicationId: appId },
  })

  // for state-based disclosures
  const creditApplication = statusData?.creditApplication
  const { appVersion, status } = creditApplication || {}
  const isAppVersion2 = appVersion === 2
  const isApprovedStatus = status === CreditApplicationStatus.APPROVED
  const isReviewOfferStatus = status === CreditApplicationStatus.REVIEW_OFFER
  const isSubmittedStatus = status === CreditApplicationStatus.SUBMITTED
  const isInReviewStatus = status === CreditApplicationStatus.IN_REVIEW
  const isInProgressStatus = status === CreditApplicationStatus.IN_PROGRESS
  const isMoreInfoNeededStatus = status === CreditApplicationStatus.MORE_INFO_NEEDED
  const isRejectedStatus = status === CreditApplicationStatus.REJECTED
  const isPollingAfterOfferAcceptance = search.get('isPollingAfterOfferAcceptance') === 'true'

  const handleSubmissionError = useCallback(() => {
    stopPollingForStatus()
    window.sessionStorage.removeItem('submissionPendingUrl')
    navigate(`/app/${appId}/section/submission-error`)
  }, [appId, navigate, stopPollingForStatus])

  const handleSubmissionSuccess = useCallback(() => {
    stopPollingForStatus()
    if (search.get('isMigration') === 'true') {
      navigate(`/app/${appId}/section/success`, { replace: true })
      window.sessionStorage.removeItem('submissionPendingUrl')
    } else {
      setSearch({})
    }
  }, [appId, navigate, search, setSearch, stopPollingForStatus])

  const navigateToDashboard = useCallback(
    (
      toastMessage: ReactNode = (
        <FormattedMessage
          defaultMessage='Application submitted'
          id='sputnik.RocketAnimation__HHjuhD'
        />
      ),
    ) => {
      stopPollingForStatus()
      navigate(PATHNAME_CONSTS.DASHBOARD_PATH, { replace: true })
      showSuccessToast(toastMessage, {
        autoHideDelay: 5000,
      })
    },
    [navigate, showSuccessToast, stopPollingForStatus],
  )

  const decisions = decisionsData?.decisions
  const frozenAuthorizedSigner = decisionsData?.frozenAuthorizedSigner
  const underwritingDeclined = decisionsData?.underwritingDeclined
  const isDecisionManualReview = !decisions || decisions?.length === 0 || decisions?.length > 3
  const hasSingleDecision = decisions?.length === 1
  const hasMultipleDecisions = decisions?.length >= 2
  const finishedPollingForCreditDecision =
    creditDecisionTimer >= CREDIT_DECISION_POLLING_MAX_TIMEOUT || decisions?.length > 0 || underwritingDeclined
  const originalStatus = search.get('originalStatus')
  const statusHasChanged = creditApplication?.status && originalStatus && creditApplication?.status !== originalStatus

  // save url to redirect back to it in case user leaves this page
  useEffect(() => {
    if (urlSearch.includes('pollingForStatusSince')) {
      //TODO: can we use something else than useLocation?
      window.sessionStorage.setItem('submissionPendingUrl', pathname + urlSearch)
    }
  }, [appId, pathname, pollingForStatusSince, urlSearch])

  // handle polling for status
  useEffect(() => {
    if (pollingForStatusSince) {
      startPollingForStatus(POLLING_RATE)
      if (statusTimer >= calculateStatusTimeoutRemaining(pollingForStatusSince)) {
        if (isAppVersion2 && !statusHasChanged && isInProgressStatus) {
          handleSubmissionError()
        } else {
          handleSubmissionSuccess()
        }
      } else {
        setTimeout(() => setStatusTimer(statusTimer + POLLING_RATE), POLLING_RATE)
      }
    }
  }, [
    handleSubmissionError,
    handleSubmissionSuccess,
    isAppVersion2,
    isInProgressStatus,
    isSubmittedStatus,
    navigateToDashboard,
    pollingForStatusSince,
    startPollingForStatus,
    statusHasChanged,
    statusTimer,
  ])

  useEffect(() => {
    if (!isAppVersion2 && statusHasChanged) {
      handleSubmissionSuccess()
    }

    // if a v2 MAC application is submitted, navigate to the dashboard
    if (isAppVersion2 && (isMoreInfoNeededStatus || (isMajorAccountChange && isInReviewStatus))) {
      navigateToDashboard()
    }

    if (
      isAppVersion2 &&
      statusHasChanged &&
      !isMoreInfoNeededStatus &&
      !isSubmittedStatus &&
      !isInReviewStatus &&
      !isApprovedStatus &&
      !isPollingAfterOfferAcceptance
    ) {
      handleSubmissionSuccess()
    }

    /* when an offer is accepted and marked as APPROVED , navigate to the dashboard */
    if (isAppVersion2 && isApprovedStatus) {
      navigateToDashboard(
        <FormattedMessage
          defaultMessage='Offer accepted'
          id='sputnik.RocketAnimation__IB0Vm6'
        />,
      )
    }
  }, [
    creditApplication?.status,
    handleSubmissionSuccess,
    isAppVersion2,
    isApprovedStatus,
    isInReviewStatus,
    isMajorAccountChange,
    isMoreInfoNeededStatus,
    isPollingAfterOfferAcceptance,
    isReviewOfferStatus,
    isSubmittedStatus,
    navigate,
    navigateToDashboard,
    pollingForStatusSince,
    search,
    setSearch,
    showSuccessToast,
    statusHasChanged,
    stopPollingForStatus,
  ])

  // handle polling for credit decision
  useEffect(() => {
    if (!pollingForStatusSince) {
      startPollingForCreditDecision(POLLING_RATE)
      if (creditDecisionTimer >= CREDIT_DECISION_POLLING_MAX_TIMEOUT) {
        stopPollingForCreditDecision()
      } else {
        setTimeout(() => setCreditDecisionTimer(creditDecisionTimer + POLLING_RATE), POLLING_RATE)
      }
    }
  }, [creditDecisionTimer, pollingForStatusSince, startPollingForCreditDecision, stopPollingForCreditDecision])

  useEffect(() => {
    if (finishedPollingForCreditDecision && !isLoadingStatusData && statusData) {
      window.sessionStorage.removeItem('submissionPendingUrl')
      if (frozenAuthorizedSigner) {
        navigate(`${appPath}${PATHNAME_CONSTS.FROZEN_CREDIT_PATH}`, { replace: true })
      } else if (underwritingDeclined || isRejectedStatus) {
        navigate(`${appPath}${PATHNAME_CONSTS.DECLINED_PATH}`, { replace: true })
      } else if (isDecisionManualReview) {
        navigate(`${appPath}${PATHNAME_CONSTS.MANUAL_REVIEW_PATH}`, { replace: false }) // shows if application doesn't have any decisions yet
      } else if (!showSecuredLine && !isAppVersion2 && decisions.length === 2) {
        navigate(`${appPath}${PATHNAME_CONSTS.DUAL_OFFER_PATH}`, { replace: false }) //routes for v1 || !showSecuredLine apps. These will be removed with release of show-secured-line and state-based-disclosures
      } else if (!showSecuredLine && !isAppVersion2) {
        navigate(`${appPath}${PATHNAME_CONSTS.PRE_APPROVED_PATH}`, { replace: true }) //routes for v1 || !showSecuredLine apps. These will be removed with release of show-secured-line and state-based-disclosures
      } else if ((showSecuredLine || isAppVersion2) && hasMultipleDecisions && isReviewOfferStatus) {
        navigate(`${appPath}${PATHNAME_CONSTS.MULTI_CREDIT_LINE_OFFER_PATH}`, { replace: false }) // route for v2 apps
      } else if ((showSecuredLine || isAppVersion2) && hasSingleDecision && isReviewOfferStatus) {
        navigate(`${appPath}${PATHNAME_CONSTS.SINGLE_CREDIT_LINE_OFFER_PATH}`, { replace: false }) // route for v2 apps
      } else {
        navigate(`${appPath}${PATHNAME_CONSTS.MANUAL_REVIEW_PATH}`, { replace: false })
      }
    }
  }, [
    frozenAuthorizedSigner,
    underwritingDeclined,
    isDecisionManualReview,
    decisions,
    navigate,
    appPath,
    showSecuredLine,
    isAppVersion2,
    hasMultipleDecisions,
    hasSingleDecision,
    isReviewOfferStatus,
    finishedPollingForCreditDecision,
    isLoadingStatusData,
    statusData,
    appVersion,
    isRejectedStatus,
  ])

  return (
    <ProcessingApplication
      isAppVersion2={isAppVersion2}
      isPollingAfterOfferAcceptance={isPollingAfterOfferAcceptance}
    />
  )
}

export default RocketAnimation
