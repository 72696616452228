import React, { ReactNode } from 'react'
import PopSheet, { PopSheetControl } from '@divvy-web/skylab.popsheet'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'

interface MfaPopsheetProps {
  isShowing: boolean
  title: NonNullable<ReactNode>
  actions: ReactNode[]
  children: ReactNode
}
const MfaPopsheet = ({ isShowing, title, actions, children }: MfaPopsheetProps) => {
  const [getClassName] = useNameFormatter('MfaPopsheet')
  return (
    <PopSheetControl isShowing={isShowing}>
      <PopSheet
        actions={actions}
        className={getClassName('popsheet')}
        title={title}
      >
        {children}
      </PopSheet>
    </PopSheetControl>
  )
}

export default MfaPopsheet
