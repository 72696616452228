import React from 'react'
import { FormattedMessage } from 'react-intl'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { css } from '@emotion/core'
import PhoneNumberInput from '../FormInputs/PhoneNumberInput'

const MfaPhoneNumberField = () => {
  const [getClassName] = useNameFormatter('MfaPhoneNumberField')
  return (
    <div
      className={getClassName('phone-number-field')}
      css={styles}
    >
      <div className={getClassName('info-text fs-unmask')}>
        <FormattedMessage
          defaultMessage="We'll send you a verification code to make sure it's really you when you sign in or access sensitive information. We’ll send you a code now to enroll."
          id='sputnik.MfaPhoneNumberField__D8u91n'
        />
      </div>
      <div className='field-container'>
        <PhoneNumberInput
          alwaysShowError={false}
          inputId='mfaPhoneNumber'
          label={
            <FormattedMessage
              defaultMessage='Mobile phone number'
              id='sputnik.MfaPhoneNumberField__L0ijz8'
            />
          }
        />
      </div>
    </div>
  )
}
const styles = css`
  .MfaPhoneNumberField {
    &-info-text {
      font: var(--tri-font-desktop-body-medium);
      margin-bottom: var(--tri-space-500);
    }
  }

  .field-container {
    margin-bottom: var(--tri-space-600);
  }
`

export default MfaPhoneNumberField
