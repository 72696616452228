import React from 'react'
import { useMakeTestId } from '@divvy-web/hooks.usenameformatter'
import { curry } from '@divvy-web/utils'
import { css } from '@emotion/core'
import { FormattedMessage } from '@divvy-web/i18n'
import { useAuth } from '../../auth'
import { PAGE_NAME_STRINGS } from '../../resources/constants'
import InviteCollaboratorsLink from '../InviteCollaboratorsLink'
import BillSideBarIcon from '../BillSideBarIcon/BillSideBarIcon'
import SideSectionContent from './SideSectionContent/SideSectionContent'

interface ReviewsSideSectionProps {
  dsaVersion: string
  inviteCollaborators?: boolean
  pageName: string
}

const ReviewsSideSection = ({ dsaVersion, inviteCollaborators, pageName }: ReviewsSideSectionProps) => {
  const makeTestId = useMakeTestId('ReviewsSideSection')
  const { PAGE_SIGN_IN, PAGE_APPLICATION_SUBMITTED } = PAGE_NAME_STRINGS
  const { email } = useAuth()
  const isSignin = pageName === PAGE_SIGN_IN
  const isApplicationSubmitted = pageName === PAGE_APPLICATION_SUBMITTED
  const shouldShowAuthorizedSignerProTip = !(dsaVersion === 'crb_business_only' || dsaVersion === 'wex_business_only')

  return (
    <div
      className='fs-unmask'
      css={sectionCss({
        isApplicationSubmitted,
        isSignin,
      })}
    >
      <div
        className='reviews-section-wrapper'
        data-testid={makeTestId('')}
      >
        <BillSideBarIcon />
        <div className='reviews-and-tips'>
          <SideSectionContent
            pageName={pageName}
            shouldShowAuthorizedSignerProTip={shouldShowAuthorizedSignerProTip}
          />
        </div>
        {inviteCollaborators && (
          <div className='signed-in-info'>
            {isApplicationSubmitted || (
              <div className='invite-collaborators'>
                <InviteCollaboratorsLink />
              </div>
            )}
            <div
              className='signed-as fs-mask'
              data-testid={makeTestId('signed-as')}
            >
              <FormattedMessage
                defaultMessage='You are signed in as {email}'
                id='sputnik.inviteCollaboratorsUtils__sy+pv5'
                values={{ email }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default ReviewsSideSection

const sectionCss = curry(
  ({ isApplicationSubmitted, isSignin }, theme) => css`
    height: 100%;

    .reviews-section-wrapper {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin: 0 var(--tri-space-500);
    }

    .reviews-and-tips {
      display: flex;
      height: 90%;
      flex-direction: column;
    }

    @media (max-height: 440px) {
      .large-image {
        ${(isSignin || isApplicationSubmitted) && 'padding-bottom: 0;'}
      }

      .reviews-and-tips {
        padding-top: var(--tri-space-500);
        padding-bottom: var(--tri-space-500);
      }
    }

    .signed-in-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: var(--tri-space-100);
      align-items: center;
      align-self: center;
      padding-bottom: var(--tri-space-400);
    }

    .signed-in-info > .signed-as {
      text-align: center;
      ${theme.type.triFontDesktopBodySmall}
    }

    .invite-collaborators > button > span {
      color: var(--tri-color-interactive-foreground-action-hover);
    }
  `,
)
