import React from 'react'
import { FormattedMessage } from '@divvy-web/i18n'
import useNameFormatter from '@divvy-web/hooks.usenameformatter'
import { FormButtons } from '../../components'
import { PAGE_NAME_STRINGS } from '../../resources/constants'
import MiscPageWrapper from '../../components/MiscPageWrapper/MiscPageWrapper'
import PageViewWrapper from '../../components/PageViewWrapper/PageViewWrapper'
import MfaPhoneNumberField from '../../components/MfaPhoneNumberField/MfaPhoneNumberField'
import MfaPhoneNumberForm from '../../components/MfaPhoneNumberForm/MfaPhoneNumberForm'

const MfaVerification = () => {
  const [getClassName, makeTestId] = useNameFormatter('MfaVerification')

  return (
    <PageViewWrapper pageName={PAGE_NAME_STRINGS.PAGE_MFA_VERIFICATION}>
      <MiscPageWrapper
        pageName={PAGE_NAME_STRINGS.PAGE_MFA_VERIFICATION}
        pageSubTitle={
          <FormattedMessage
            defaultMessage="Let's verify your identity"
            id='sputnik.mfaVerification__tw7UNB'
          />
        }
        pageTitle={
          <FormattedMessage
            defaultMessage='Welcome'
            id='sputnik.mfaVerification__PwaN2o'
          />
        }
      >
        <MfaPhoneNumberForm>
          <MfaPhoneNumberField />
          <div className={getClassName('button-wrapper')}>
            <FormButtons
              disableSaveOnInvalid
              hideExit
              hidePrevious
              dataTestId={makeTestId('buttons')}
              nextButtonText={
                <FormattedMessage
                  defaultMessage='Send code'
                  id='sputnik.MfaVerification__MTwUmz'
                />
              }
              nextButtonType='submit'
              pageName={PAGE_NAME_STRINGS.PAGE_MFA_VERIFICATION}
            />
          </div>
        </MfaPhoneNumberForm>
      </MiscPageWrapper>
    </PageViewWrapper>
  )
}

export default MfaVerification
